import styled from "styled-components";
import { breakpoints } from "utils/mixins";

export const Wrapper = styled.div`
  height: 300px;
  margin-bottom: 20px;
  @media ${breakpoints.tablet} {
    height: 450px;
  }
  @media ${breakpoints.laptop} {
    height: 600px;
  }
`;
