import React from "react";

import Img from "gatsby-image";

import { Wrapper } from "./Galeria.styles";

const Galeria = (props) => {
  //PROPS
  const { blockGallery } = props;
  //PROPS

  return (
    <Wrapper>
      {blockGallery.map((item, index) => {
        return (
          <Img
            key={index}
            className={
              blockGallery[1]
                ? "image-gallery"
                : "image-gallery image-gallery--solo"
            }
            fluid={item?.localFile?.childImageSharp.fluid}
            alt={item?.altText}
          />
        );
      })}
    </Wrapper>
  );
};

export default Galeria;
