import styled from "styled-components";
import { breakpoints } from "utils/mixins";

export const NewsStyles = styled.div`
  @media ${breakpoints.laptop} {
    padding-top: 144px;
    .news-share {
      display: flex;
      padding-top: 20px;
      justify-content: space-between;
    }
    button {
      margin-right: 0px;
    }
  }
  padding-top: 68px;
  width: 100%;

  .news-content {
    padding-top: 20px;
    width: 100%;
  }

  h3 {
    text-align: left;
  }

  .news-top-image {
    width: 100%;
  }

  .news-share {
    display: block;
    padding-top: 5px;
    margin-bottom: 20px;
  }

  button {
    margin: 10px 0 0 0;
    font-weight: bold;
  }

  .m_dicas {
    &__social-item {
      display: inline-block;
      height: 35px;
      width: 35px;
      overflow: hidden;
      border-radius: 50%;
      background-color: $color__green;
      transition: all 0.3s;
      margin: 0 2px;
      button {
        margin: 0 2px;
        display: inline-block;
        height: 35px;
        width: 35px;
        overflow: hidden;
        border-radius: 50%;
        background-color: $color__green;
        transition: all 0.3s;
      }
      &:hover {
        background-color: $color__green;
      }

      img {
        height: 100%;
        min-width: 35px;
        max-width: 1000px;
        width: auto;
        margin-left: 0;
        transition: all 0.3s;
      }

      &--face {
        background-color: #1877f2;

        img {
          margin-left: -5px;

          &:hover {
            margin-left: -53px;
          }
        }
      }

      &--email {
        background-color: #f15a29;

        &:hover {
        }

        img {
          &:hover {
            margin-left: -35px;
          }
        }
      }
    }
  }
`;
