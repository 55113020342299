import React from "react";

import ReactPlayer from "react-player";

import { Wrapper } from "./VideoLink.styles";

const VideoLink = (props) => {
  //PROPS
  const { youtubeUrl } = props;
  //PROPS

  return (
    <Wrapper>
      <ReactPlayer
        className="react-player"
        url={youtubeUrl}
        width="100%"
        height="100%"
        controls={true}
      />
    </Wrapper>
  );
};

export default VideoLink;
