import styled from "styled-components";

import { breakpoints } from "utils/mixins";

export const Wrapper = styled.div`
  padding-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .image-gallery {
    width: 100%;
    margin: 2rem 0;
  }
  @media ${breakpoints.laptop} {
    .image-gallery {
      width: 48%;
      margin: 0;
      margin-bottom: 4%;
      &--solo {
        width: 100%;
      }
    }
  }
`;
