import React from "react";

import HTMLReactParser from "html-react-parser";

import { Wrapper } from "./Texto.styles";

const Texto = (props) => {
  //PROPS
  const { blockText } = props;
  //PROPS

  return <Wrapper>{blockText && HTMLReactParser(blockText)}</Wrapper>;
};

export default Texto;
