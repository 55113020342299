import React from "react";
import NewsDetail from "../../components/newsDetail";

const Noticia = (props) => {
  const {
    data: {
      new: { news_post }
    }
  } = props;

  return (
    <div className="container">
      <NewsDetail data={news_post} />
    </div>
  );
};

export const query = graphql`
  query noticia($id: String) {
    new: wpNoticia(id: { eq: $id }) {
      title
      news_post {
        title
        introthumbnailText
        imagemListagem {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        imagemFachada {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        conteudo {
          ... on WpNoticia_NewsPost_Conteudo_VideoLink {
            youtubeUrl
          }
          ... on WpNoticia_NewsPost_Conteudo_Texto {
            blockText
          }
          ... on WpNoticia_NewsPost_Conteudo_Galeria {
            blockGallery {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2400, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Noticia;
