import React from "react";

import Img from "gatsby-image";
import Link from "gatsby-link";

import Button from "components/button";
import { red, white } from "theme/colors";

import { NewsStyles } from "./NewsDetail.styles";
import Galeria from "./Galeria";
import VideoLink from "./VideoLink";
import Texto from "./Texto";
import HTMLReactParser from "html-react-parser";
import Share from "../share/Share";

const NewsContent = (props) => {
  //PROPS
  const { rows } = props;
  //PROPS
  if (!!rows) {
    return rows
      .filter((o) => o !== null)
      .map(({ __typename, ...rowData }, index) => {
        const type = __typename.split("_").slice(-1)[0];

        const components = {
          Galeria,
          Texto,
          VideoLink
        };
        const Component = components[type];
        return Component && <Component {...rowData} />;
      });
  } else {
    return null;
  }
};

const NewsDetail = (props) => {
  const {
    data: { imagemFachada, title, conteudo, introthumbnailText }
  } = props;

  return (
    <NewsStyles>
      <h3 style={{ marginTop: "40px" }}>{title}</h3>

      {introthumbnailText && HTMLReactParser(introthumbnailText)}
      <div className={"news-top-image"}>
        <Img
          fluid={imagemFachada?.localFile?.childImageSharp.fluid}
          alt={imagemFachada?.altText}
        />
      </div>
      <div className="news-content">
        <NewsContent rows={conteudo} />
      </div>

      <div className={"news-share"}>
        <div>
          <p>Partilhar:</p>
          <ul className="m_dicas__social">
            <li className="m_dicas__social-item">
              <a
                target="_blank"
                className="email share"
                href={`http://www.facebook.com/share.php?u=${window.location.href}&amp;title=${title}`}
                title="Facebook"
                rel="noopener noreferrer"
              >
                <img src="/images/icons/facebook.png" alt="facebook" />
              </a>
            </li>

            <li className="m_dicas__social-item ">
              <a
                className="email share"
                href={`mailto:?subject=${title} - Notícias Motospazio&body=Acho que vais gostar desta notícia: <a style="cursor:pointer" href="${window.location.href}">${window.location.href}</a>`}
                title="Email"
                rel="noopener noreferrer"
              >
                <img src="/images/icons/email.png" alt="email" />
              </a>
            </li>
            <Share uri={window.location.href} />
          </ul>
        </div>
        <Link to={"/noticias"}>
          <Button
            text="VER TODAS"
            backgroundColor={red.default}
            color={white.default}
            minWidth
          />
        </Link>
      </div>
    </NewsStyles>
  );
};

export default NewsDetail;
